<template>
	<b-container fluid>
		<b-row>
			<b-col sm="12">
				<card>
					<template v-slot:headerTitle>
						<h4 class="card-title">Payments of ({{student.name}})</h4>
					</template>
					<template v-slot:headerAction>
						<div class="float-right">
							<router-link :to="{ name: 'students' }" class="btn btn-primary mt-2 mr-1 btn-sm"><i class="fas fa-angle-double-left"></i> Back</router-link>
							<b-button v-b-modal.payment-modal variant="primary" class="btn-sm mt-2 mr-1">Add Payment</b-button>
						</div>
					</template>
					<template v-slot:body>						

						<!--- Listing starts ----->
						<div class="table-responsive">
							<table id="datatable" class="table data-table table-striped table-bordered dataTable">
								<thead>
									<tr>
										<th>Title</th>	
										<th>Payment Received</th>
										<th>Notes</th>
										<th>Received on</th>
										<th>Options</th>
									</tr>
								</thead>
								<tbody v-if="Boolean(payments.length)">
									<tr v-for="payment in payments" :key="payment.id">
										<td>{{payment.title}}</td>
										<td>Rs. {{payment.payment_received}}</td>
										<td>{{payment.notes}}</td>
										<td>{{payment.received_date}}</td>
										<td>
											<b-button variant="primary" class="btn btn-sm mt-2 mr-1" @click="editPayment(payment)">Edit</b-button>
											<b-button variant="danger" class="btn mt-2 mr-1 btn-sm" @click="deletePayment(payment.id)">Delete</b-button>
										</td>
									</tr>								
								</tbody>
								<tbody v-else>
									<tr>
										<td colspan="5" class="text-center">No payment records found</td>
									</tr>
								</tbody>
							</table>
						</div>
						<!-- Listing ends -->
						<!---- modal starts ---->
						<b-modal ref="payment-modal" id="payment-modal" :title="modal_title" hide-footer @hidden="clearForm()">
							<b-form @submit.prevent="addPayment">								
								<b-col md="12" class="form-group">
									<label class="control-label">Title<span class="required">*</span></label>
									<b-form-input v-model="payment.title" v-bind:class="{ 'is-invalid': Boolean(errors.title) }"></b-form-input>
									<div v-for="(val, index) in errors.title" :key="index" class="invalid-feedback">
										<p class="mb-0">{{ val }}</p>
									</div>
								</b-col>
								
								<b-col md="12" class="form-group">
									<label class="control-label">Payment Received<span class="required">*</span></label>
									<b-form-input v-model="payment.payment_received" v-bind:class="{ 'is-invalid': Boolean(errors.payment_received) }"></b-form-input>
									<div v-for="(val, index) in errors.payment_received" :key="index" class="invalid-feedback">
										<p class="mb-0">{{ val }}</p>
									</div>
								</b-col>	

								<b-col md="12" class="form-group">
									<label>Payment Received Date<span class="required">*</span></label>
									<b-form-input type="date" v-model="payment.received_time" value-as-string v-bind:class="{ 'is-invalid': Boolean(errors.received_time) }"></b-form-input>
									<div v-for="(val, index) in errors.received_time" :key="index" class="invalid-feedback">
										<p class="mb-0">{{ val }}</p>
									</div>
								</b-col>

								<b-col md="12" class="form-group">
									<label class="control-label">Notes<span class="required">*</span></label>
									<b-form-textarea rows="3" v-model="payment.notes" v-bind:class="{ 'is-invalid': Boolean(errors.notes) }"></b-form-textarea>
									<div v-for="(val, index) in errors.notes" :key="index" class="invalid-feedback">
										<p class="mb-0">{{ val }}</p>
									</div>
								</b-col>	
								
								<b-col md="12">
									<b-button type="submit" class="mr-3" variant="primary">{{ button_title }}</b-button>
									<b-button class="" variant="light" @click="hideModal('payment-modal')">Close</b-button>
								</b-col>
							</b-form>
						</b-modal>
						<!---- modal ends ----->
						
					</template>
				</card>
			</b-col>
		</b-row>
	</b-container>
</template>
<script>
export default {
	name: "StudentPayments",
	data() {
		return {
			payments: [],
			student: {},
			payment: {
				id: null,
			   	title: "",
			   	payment_received: "",
			   	notes: "",
			   	received_time: ''
			},
			
			modal_title: "Add Payment",
			button_title: "Save",
			edit: false,			
			token: "",
			errors: {},			

		};
	},
	mounted() {
			
	},

	created() {
		this.fetchPayments();
		this.fetchStudent();
	},

     methods: {
		    
		   fetchPayments(page_url = null) {
		    	this.fetchStudent();
				let url = "/api/student_payments?student_id=" + this.$route.params.id;
				page_url = page_url || url;

				this.getToken();
				this.$axios
					.get(page_url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data.data;
						this.payments = responseData.payments;
						this.pagination = responseData.links;
						console.log(this.payments);
					})
					.catch((err) => console.log(err));
			},
            
            fetchStudent() {
		          let url = '/api/students/details?student_id=' + this.$route.params.id ;
		            
		         this.getToken();
		         this.$axios
		            .get(url, {headers: {Authorization: 'Bearer ' + this.token,}})
		            .then(res => {
		               var responseData = res.data.data;               
		               this.student = responseData.student; 
		               console.log(this.student);             
		            })
		            .catch(err => console.log(err));
		      }, 

			getToken() {
				if (localStorage.token) {
					this.token = localStorage.token;
				} else {
					this.$vToastify.error("Session expired, please login again");
					this.$router.push({ name: "auth.login" });
				}
			},

			showModal(modal) {
				this.$refs[modal].show();
			},

			hideModal(modal) {
				this.$refs[modal].hide();
			},
            
             addPayment() {
             	// alert(this.payment.payment_received);
				this.getToken();
				let formData = new FormData();
				formData.append("title", this.payment.title);
				formData.append("notes", this.payment.notes);
				formData.append("payment_received", this.payment.payment_received);
				formData.append("received_time", this.payment.received_time);
				formData.append("student_id", this.$route.params.id);
				const config = {
					headers: {
						"content-type": "multipart/form-data",
						Authorization: "Bearer " + this.token,
					},
				};
				let url;
				if (!this.edit) {
					url = "/api/student_payments/add";
					this.active_page_url = null;
				} else {
					url = "/api/student_payments/update";
					formData.append("student_payment_id", this.payment.id);
				}
				this.$axios
					.post(url, formData, config)
					.then((response) => {
						if (response.data.status) {
							this.$vToastify.success(response.data.message);
						} else {
							this.$vToastify.error(response.data.message);
						}
						this.clearForm();
						this.hideModal("payment-modal");
						this.fetchPayments(this.active_page_url);
					})
					.catch((error) => {
						console.log(error.response.data);

						if (error.response) {
							if (error.response.data.message) {
								this.errors = error.response.data.message;
								this.$vToastify.error(error.response.message);
							} else {
								this.$vToastify.error("Error!");
							}
						} else {
							this.$vToastify.error("Error!");
						}
					});
			},

			editPayment(payment) {	
				this.edit = true;	
				this.payment.id = payment.id;
				this.payment.title = payment.title;
				this.payment.notes = payment.notes;
				this.payment.payment_received = payment.payment_received;
				this.payment.received_time = payment.received_time;
				this.errors = {};		
				this.modal_title = "Edit Payment";
				this.button_title = "Update";
				this.showModal("payment-modal");
			},
			clearForm() {				
				this.edit = false;
				this.modal_title = "Add Payment";
				this.button_title = "Add";

				this.payment.title = "";
				this.payment.payment_received = null;
				this.payment.notes = "";
                this.received_time = "";
			},
			
			deletePayment(payment_id) {
	         this.delete = false;
	         this.$bvModal.msgBoxConfirm('Are you sure you want to delete this payment details?', {
	            title: 'Confirm',
	            size: 'sm',
	            buttonSize: 'sm',
	            okVariant: 'danger',
	            okTitle: 'Yes',
	            cancelTitle: 'No',
	            footerClass: 'p-2',
	            hideHeaderClose: false,
	            centered: true
	         })
	         .then(value => {
	            this.delete = value
	            if(this.delete){
	               this.getToken();
	               this.$axios
	                  .get("/api/student_payments/delete?student_payment_id=" + payment_id,{headers: {Authorization: 'Bearer ' + this.token,}})
	                  .then(response => {
	                     if(response.data.status) {
	                           this.$vToastify.success(response.data.message);
	                     } else {
	                           this.$vToastify.error(response.data.message);
	                     }	                     
	                     this.fetchPayments();
	                  })
	                  .catch(err => console.log(err));
	            }
	         })
	         .catch(err => {
	            console.log(err);
	         })         
	      },
		},	
};
</script>
